import React from 'react';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlined from '@mui/icons-material/LocalPhoneOutlined';
import Text from 'components/Text';
import * as Styled from './styles';
import { useNavigate } from 'react-router-dom';
import { IMG_PLACEHOLDER } from 'api';

const PendingYoungCard = (props) => {

  const young = props.props;
  const navigate = useNavigate();

  const sendEmail = (email) => {
    if (!email) return;
    window.open('mailto: ' + email, '_blank');
  }

  const sendMessage = (number) => {
    if (!number) return;
    navigator.clipboard.writeText(number);
  }

  const getStep = (step) => {
    if (step == 0) return 'Dados pessoais';
    if (step == 1) return 'Dados contratuais';
    if (step == 2) return 'Uploads';
  }

  const open = (id, step) => {
    if (step == 0) {
      navigate('/cadastrar-aprendiz', { state: { id: id } });
    }

    if (step == 1) {
      navigate('/cadastrar-aprendiz-dois', { state: { id: id } });
    }

    if (step == 2) {
      navigate('/cadastrar-aprendiz-tres', { state: { id: id } });
    }
  }

  return (
    <>
      <Styled.ItemContainer
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>

          <div onClick={() => { open(young.id_jovem, young.etapa_cadastro) }} style={{ display: 'flex', width: 50, flexDirection: 'row', alignItems: 'center' }}>
            <img alt='Placeholder' src={IMG_PLACEHOLDER} width="40" height="40" style={{ borderRadius: 100 }} />
          </div>

          <div onClick={() => { open(young.id_jovem, young.etapa_cadastro) }} style={{ display: 'flex', width: '70%', flexDirection: 'row', alignItems: 'center', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            <Text style={{ fontSize: 14, marginLeft: 8, color: '#F2F2F3', fontWeight: 600 }}>{young.nome}</Text>
          </div>

          <div onClick={() => { open(young.id_jovem, young.etapa_cadastro) }} style={{ display: 'flex', width: '20%', flexDirection: 'row', alignItems: 'center', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            <Text style={{ fontSize: 14, marginLeft: 8, color: '#F2F2F3', fontWeight: 600 }}>{getStep(young.etapa_cadastro)}</Text>
          </div>

          <div onClick={() => { sendEmail(young.email) }} style={{ display: 'flex', width: 20, flexDirection: 'row', alignItems: 'center', marginLeft: 20 }}>
            {(young.email) ? <EmailOutlined fontSize='medium' style={{ color: '#4789EB', }} /> : <EmailOutlined fontSize='medium' style={{ color: '#93979F', }} />}
          </div>

          <div onClick={() => { sendMessage(young.whatsapp) }} style={{ display: 'flex', width: 20, flexDirection: 'row', alignItems: 'center', marginLeft: 20 }}>
            {(young.whatsapp) ? <LocalPhoneOutlined fontSize='medium' style={{ color: '#4789EB', }} /> : <LocalPhoneOutlined fontSize='medium' style={{ color: '#93979F', }} />}
          </div>

        </div>
      </Styled.ItemContainer>
    </>
  );
};

export default PendingYoungCard;

import React from 'react';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlined from '@mui/icons-material/LocalPhoneOutlined';
import Text from 'components/Text';
import * as Styled from './styles';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { IJB_URL, IMG_PLACEHOLDER } from 'api';

const YoungCard = (props) => {

  const young = props.props;
  const navigate = useNavigate();

  const sendEmail = (email) => {
    if (!email) return;
    window.open('mailto: ' + email, '_blank');
  }

  const sendMessage = (number) => {
    if (!number) return;
    navigator.clipboard.writeText(number);
  }

  const open = (id) => {
    navigate('/jovem/' + id);
  }

  return (
    <Styled.ItemContainer
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item container alignItems="center" xs={12} sm={12} md={4} onClick={() => { open(young.id_jovem, young.etapa_cadastro) }}>
        <img
          src={young.foto ? `${IJB_URL}${young.foto}` : IMG_PLACEHOLDER}
          width="40"
          height="40"
          alt='Foto do jovem'
          style={{ borderRadius: 100, marginRight: 10 }}
        />

        <Grid item style={{ padding: 5, width: 95 }}>
          <div style={{ padding: '5px 10px', backgroundColor: '#364563', borderRadius: 8 }} align="center">
            <Text size={10} bold style={{ width: '100%' }} align="center">
              {young?.ead}
            </Text>
          </div>
        </Grid>

        <Grid item style={{ marginLeft: 5, width: '50%' }}>
          <Text size={13} style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '95%', overflow: 'hidden' }}>{young?.nome}</Text>
        </Grid>
      </Grid>

      <Grid md={2} style={{ padding: 5 }} onClick={() => { open(young.id_jovem, young.etapa_cadastro) }}>
        <Text size={13} style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '95%', overflow: 'hidden' }}>{young?.nome_fantasia}</Text>
      </Grid>
      <Grid md={2} onClick={() => { open(young.id_jovem, young.etapa_cadastro) }}>
        <Text size={13}>{young?.cidade}</Text>
      </Grid>
      <Grid md={2} style={{ padding: 5 }} onClick={() => { open(young.id_jovem, young.etapa_cadastro) }}>
        <Text size={13}>{young?.cnpj}</Text>
      </Grid>

      <Grid container item md={2} justifyContent="space-between" alignItems="center">
        <Text size={13} italic color="#93979F" style={{ marginRight: 10 }}>
          {(young.meses_contratado !== '-') ? ('há ' + young.meses_contratado + ' meses') : '-'}
        </Text>
        <div>
          <EmailOutlined fontSize='medium' style={{ color: young.email ? '#4789EB' : '#93979F' }} onClick={() => { sendEmail(young.email) }} />
          <LocalPhoneOutlined fontSize='medium' style={{ color: young.whatsapp ? '#4789EB' : '#93979F', marginLeft: 15 }} onClick={() => { sendMessage(young.whatsapp) }} />
        </div>
      </Grid>

    </Styled.ItemContainer >
  );
};

export default YoungCard;

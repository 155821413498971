import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Add } from '@mui/icons-material'
import { Fab } from '@mui/material';

import Text from 'components/Text';
import YoungCard from './components/YoungCard';
import PendingYoungCard from './components/PendingYoungCard';
import Search from './components/Search';

import Theme from 'style/Theme';

const YoungsScreen = () => {
  const navigate = useNavigate();
  const listYoungs = useStoreActions(({ youngs }) => youngs.list);
  const listPending = useStoreActions(({ youngs }) => youngs.listPending);
  const youngsList = useStoreState(({ youngs }) => youngs.youngs);
  const pendingYoungsList = useStoreState(({ youngs }) => youngs.pendingYoungs);
  const [filterText, setFilterText] = useState(null);
  const [filtering, setFiltering] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);

  const [filteredYoungs, setFilteredYoungs] = useState(false);
  const [tabBg, setTabBg] = useState(['#1E262D', 'transparent']);
  const [tabTxtBg, setTabTxtBg] = useState(['#F2F2F3', '#364563']);
  const [pending, setPending] = useState(false);


  const selectTab = (tab) => {
    if (tab == 1 && selectedTab != 1) {
      setSelectedTab(1);
      listYoungs();
      setTabBg(['#364563', 'transparent']);
      setTabTxtBg(['#F2F2F3', '#364563']);
      setPending(false);
    } else if (tab == 2 && selectedTab != 2) {
      setSelectedTab(2);
      listPending();
      setTabBg(['transparent', '#364563']);
      setTabTxtBg(['#364563', '#F2F2F3']);
      setPending(true);
    }
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 50,
    height: 50,
    bgcolor: Theme.dark.background,
    borderRadius: 5,
    boxShadow: 24,
    alignItems: 'center',
    justifyContent: 'center',
    p: 4,
  };

  const init = async () => {
    await getYoungs();
  };

  const getYoungs = async () => {
    await listYoungs();
    await listPending();
  }

  const filter = async (value) => {
    setFilterText(value);
    let aux = value.toLowerCase();

    if (!pending) {
      const result = youngsList.filter(item => ((item.nome.toLowerCase().indexOf(aux) != -1)
        || (item.cnpj.toLowerCase().indexOf(aux) != -1)
        || (item.nome_fantasia.toLowerCase().indexOf(aux) != -1)
        || (item.cidade.toLowerCase().indexOf(aux) != -1)));

      if (value && value.trim() != '' && result.length < youngsList.length) {
        setFilteredYoungs(result);
        setFiltering(true);
      } else {
        setFilteredYoungs([]);
        setFiltering(false);
      }
    } else {
      const result = pendingYoungsList.filter(item => ((item.nome.toLowerCase().indexOf(aux) != -1)
        || (item.cnpj.toLowerCase().indexOf(aux) != -1)
        || (item.nome_fantasia.toLowerCase().indexOf(aux) != -1)
        || (item.cidade.toLowerCase().indexOf(aux) != -1)));

      if (value && value.trim() != '' && result.length < pendingYoungsList.length) {
        setFilteredYoungs(result);
        setFiltering(true);
      } else {
        setFilteredYoungs([]);
        setFiltering(false);
      }
    }


  }

  const newYoung = () => {
    navigate('/cadastrar-aprendiz');
  }

  useEffect(() => {
    getYoungs();
  }, [])

  const renderItems = () => {
    return (
      <Grid container direction="column" sx={{ backgroundColor: Theme.dark.background }}>
        {/* {lessons.length > 0 ? lessons.map((item, index) => <LessonCard item={item} key={`${index}_${item?.id}`} />) : <Text align="center" size={18} bold color="#ccc" style={{ marginTop: 30 }}>Ainda não há nenhuma aula concluída!</Text>} */}
      </Grid>
    );
  };

  return (
    <div style={{ backgroundColor: Theme.dark.background, padding: 40, flex: 1 }}>
      <Text
        color="#93979F"
        bold
        size={24}
      >
        Lista de aprendizes
      </Text>

      <Search handleSearch={filter} />

      <Grid container style={{ padding: 10, marginTop: 10 }}>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Button
            background={tabBg[0]}
            style={{
              padding: 5,
              width: '100%',
              height: 40,
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderBottom: '1px solid #364563'
            }}
            height={40}
            onClick={() => { selectTab(1) }}
          >
            <Text color={tabTxtBg[0]} size={16} style={{ weight: 600, letterSpacing: '1px' }}>CONTRATADOS</Text>
          </Button>
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Button
            background={tabBg[1]}
            style={{
              padding: 5,
              width: '100%',
              height: 40,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
              borderBottom: '1px solid #364563',
              borderLeft: '1px solid #364563'
            }}
            height={40}
            onClick={() => { selectTab(2) }}
          >
            <Text color={tabTxtBg[1]} size={16} style={{ weight: 600, letterSpacing: '1px' }}>PENDENTES ({(pendingYoungsList) ? pendingYoungsList.length : '?'})</Text>
          </Button>
        </Grid>
      </Grid>

      {selectedTab === 1 && (
        <Grid container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >

          <Grid item container alignItems="center" xs={12} sm={12} md={4}>

            <Grid item style={{ padding: 5, width: 95 }}>
              <div style={{ padding: '5px 10px' }} align="center">
                <Text size={10} bold style={{ width: '100%' }} align="center">
                </Text>
              </div>
            </Grid>

            <Grid item style={{ marginLeft: 5, width: '50%' }}>
              <Text size={14} bold color="#93979F" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '95%', overflow: 'hidden', marginLeft: -10 }}>Nome</Text>
            </Grid>
          </Grid>

          <Grid md={2} style={{ padding: 5 }} >
            <Text size={14} bold color="#93979F" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '95%', overflow: 'hidden', marginLeft: 7 }}>Empresa</Text>
          </Grid>
          <Grid md={2} >
            <Text size={14} bold color="#93979F">Cidade</Text>
          </Grid>
          <Grid md={2} style={{ padding: 5 }} >
            <Text size={14} bold color="#93979F" style={{ marginLeft: -10 }}>Estabelecimento</Text>
          </Grid>

          <Grid container item md={2} justifyContent="space-between" alignItems="center">
            <Text size={14} bold color="#93979F" style={{ marginLeft: -20 }}>
              Contrato
            </Text>
          </Grid>

        </Grid>
      )}



      <div style={{ overflowY: 'scroll', height: '60vh' }}>

        {(!pending) ? ((youngsList && !filtering) ? youngsList.map((item) => { return <YoungCard key={item.id_jovem} props={item} /> }) : null) : null}
        {(!pending) ? ((filteredYoungs && filtering) ? filteredYoungs.map((item) => { return <YoungCard key={item.id_jovem} props={item} /> }) : null) : null}

        {(pending) ? ((pendingYoungsList && !filtering) ? pendingYoungsList.map((item) => <PendingYoungCard key={item.id_jovem} props={item} />) : null) : null}
        {(pending) ? ((filteredYoungs && filtering) ? filteredYoungs.map((item) => <PendingYoungCard key={item.id_jovem} props={item} />) : null) : null}
      </div>

      <Fab onClick={newYoung} color="primary" aria-label="add" style={{ right: 20, bottom: 20, position: 'fixed' }}>
        <Add />
      </Fab>

    </div >
  );
}

export default YoungsScreen;
